/*
***********************************************
****************** Home Page ******************
***********************************************
* Home Page, the Demo journey will be decided *
* on this page, atm has a very simple design  *
***********************************************
*/

import React, { useEffect, useState } from 'react';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import pic from '../assets/car_insurance.jpg';

function Home(){
    
    const [coin, setCoin] = useState(((localStorage.getItem("coin")) != null) ? (localStorage.getItem("currency") + " / " + localStorage.getItem("coin")) : 'EUR / €');
    const [scheme, setScheme] = useState('');
    const [country, setCountry] = useState('PT');

    localStorage.removeItem('inputFields');
    localStorage.removeItem('customer');
    localStorage.removeItem('amount');
    localStorage.removeItem('policy');
    localStorage.removeItem('order');
    localStorage.removeItem('step');
    localStorage.removeItem('policyId');
    localStorage.removeItem('coin');
    localStorage.removeItem('currency');

    return (
            <div>
                <NavBar 
                    placement={'end'}
                    setCoin={setCoin}
                    setScheme={setScheme}
                    setCountry={setCountry}
                    menu={true}
                    home={true}
                />
                <Container fluid className='' style={{minHeight: (window.innerHeight - '200')}}>
                <Row className='h-75'>
                    <Col className='d-flex justify-content-center align-items-center ' >
                        <Card className='border-0 justify-content-center col-8'>
                        <Card.Body>
                            <Card.Title className='text-imburse fs-1 fw-bold my-3'>Car insurance that is always there for you</Card.Title>
                            <Card.Text className='text-imburse my-3'>
                            In addition to a quick repair with a lifetime 
                            warranty, you receive a free replacement car, 
                            public transport ticket or cab voucher. With our 
                            car insurance, you always spay mobile.
                            </Card.Text>
                            <Button className='btn-imburse my-3' size="lg" href='/policy-details'>Get a quote today</Button>{' '}
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col className='px-0 w-100'>
                    <Image src={pic} className="top-rounded " style={{height: (window.innerHeight-'200'), width: (window.innerWidth/2), objectFit: 'cover'}}/>
                    </Col>
                </Row>
            </Container>
            <Footer />
            </div>
    )
}

export default Home;
