/*
***********************************************
***************** Policy Page *****************
***********************************************
* Page where the demo policy will be chosen,  *
* this will define the amount of the policy.  *
***********************************************
*/

import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//import ReactFlagsSelect from 'react-flags-select';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


export class FormPolicyCover extends Component{
    //proceed to next step
    continue = (input) => e => {
        e.preventDefault();
        
        localStorage.setItem("policy",JSON.stringify(input));
        this.props.handlePolicyChange ('policy', input);

        localStorage.setItem("inputFields",JSON.stringify(this.props.values));
        console.log("Values saved ... ", this.props.values);

        this.props.nextStep(); 
    }

    goBack = e => {
        e.preventDefault();
        this.props.previousStep();
    }
    
    render(){
        const { values } = this.props;
        const policyPosition= {'basic': 0,'optimum':1,'premium':2};

        function getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            console.log('Age Calculates :', age);
            return age;
        }

        function caclPolicy(policies){
            var date = new Date();
            var expiryDate = new Date().setFullYear(date.getFullYear() + 1);
            var age = getAge(values.dateOfBirth);
            var carAge = getAge(values.dateOfRegistration);
            var basicAmount = 150; var optAmount = 200; var premAmount = 400;
            var basePolicies = [{'type': 'basic', 'title': 'Basic cover', 'amount' : basicAmount, 'expiryDate' : expiryDate, 'noShow' : false},
                            {'type': 'optimum', 'title': 'Optimum cover', 'amount' : optAmount, 'expiryDate' : expiryDate , 'noShow' : false}, 
                            {'type': 'premium', 'title': 'Premium cover','amount' : premAmount, 'expiryDate' : expiryDate , 'noShow' : false}];
            
            //Add make value over policy value
            if(values.make === "Alfa Romeo" || values.make === "Honda"){
                basePolicies.forEach(element => {
                    element.amount += 75;                     
                });
            }else if(values.make === "Ferrari" || values.make === "Mercedes"){
                basePolicies.forEach(element => {
                    if( basePolicies.type === 'basic'){
                        element.noShow = true;
                    }else{
                        element.amount += 125;                     
                    }
                });
            } else if(values.make === "Aston Martin" || values.make === "McLaren"){
                basePolicies.forEach(element => {
                    if( basePolicies.type === 'basic'){
                        element.noShow = true;
                    }else{
                        element.amount += 180;                     
                    }
                });
            } else {
                console.log("something when wrong - deciding MAKE");
            }

            //Add age value over policy value
            if (age < 18 ){
                console.log('should not be allow to drive');
            } /* else */ 
            if (age >=18 && age <= 25){
                console.log('age between 18 && 25');
                basePolicies.forEach(element => {
                    element.amount += 125;                     
                });
            } else if (age >=26 && age <= 50){
                console.log('age between 25 && 50');
                basePolicies.forEach(element => {
                    element.amount += 75;                     
                });
            } else if (age >=61){
                console.log('age over 61');
                basePolicies.forEach(element => {
                    element.amount += 170;                     
                });
            }else {
                console.log("something when wrong - deciding Age");
            }

            //Add car age value over policy value
            if (carAge < 0 ){
                console.log('There is no car yet');
            } /* else */ 
            if (carAge >=0 && carAge <= 1){
                console.log('New car - 0 to 1');
                basePolicies.forEach(element => {
                    element.amount += 125;                     
                });
            } else if (carAge >1 && carAge <= 7){
                console.log('middle age car - 2 to 7');
                basePolicies.forEach(element => {
                    element.amount += 75;                     
                });
            } else if (carAge >8 && carAge <= 20){
                console.log('old car - 8 to 20');
                basePolicies.forEach(element => {
                    element.amount += 75;                     
                });
            } else if (carAge >= 21){
                console.log('classic car +21');
                basePolicies.forEach(element => {
                    element.amount += 170;                     
                });
            }else {
                console.log("something when wrong - deciding Car Age");
            }
            return basePolicies;
        }

        const getCurrency = () => {
            if(values.cityOfResidence === "London" ){
                localStorage.setItem("coin",'£');
                localStorage.setItem("currency",'GBP');
                localStorage.setItem("country",'GB');
                return '£';
            }else if(values.cityOfResidence === "Zurich" ){
                localStorage.setItem("coin",'CHF');
                localStorage.setItem("currency",'CHF');
                localStorage.setItem("country",'CH');
                return 'CHF';
            }else if(values.cityOfResidence === "Lisbon" ){
                localStorage.setItem("coin",'€');
                localStorage.setItem("currency",'EUR');
                localStorage.setItem("country",'PT');
                return '€';
            }else if(values.cityOfResidence === "Sydney" ){
                localStorage.setItem("coin",'AU$');
                localStorage.setItem("currency",'AUD');
                localStorage.setItem("country",'AU');
                return 'AU$';
            }else if(values.cityOfResidence === "Wellington" ){
                localStorage.setItem("coin",'NZ$');
                localStorage.setItem("currency",'NZD');
                localStorage.setItem("country",'NZ');
                return 'NZ$';
            }else if(values.cityOfResidence === "Boston" ){
                localStorage.setItem("coin",'$');
                localStorage.setItem("currency",'USD');
                localStorage.setItem("country",'US');
                return '$';    
            }else { console.log("something when wrong - deciding Currency");}
        }



        var policies = caclPolicy();
        console.log("Policies calculated : ", policies);
        console.log("Currency based on Residence : ", getCurrency());
        
        return (
            <Form className="w-100 mx-5">
                <div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                    <h2 className='fw-bold text-imburse'>Here is your quote</h2>                    
                </div>

                <Form.Group className="mb-3 d-flex justify-content-start align-items-center" controlId="formPolicySelection">
                    { /*if (policies[policyPosition.basic].noShow) */}
                    <Card className='mx-1 w-25 d-flex justify-content-center align-items-center' style={{ 'borderColor': '#adb5bd', 'borderWidth' : 'thin'}}>
                        <Card.Body className="w-100 px-0">
                            <Card.Title className="mb-4 text-center px-3 fw-bold">{policies[policyPosition.basic].title}</Card.Title>
                            <Card.Title className="mb-4 text-center px-3 fw-bold">{policies[policyPosition.basic].amount} {getCurrency()}</Card.Title>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="bg-imburse-light px-3">1 year free of charge</ListGroup.Item>
                                <ListGroup.Item className='px-3'>Sum Insured 100 million</ListGroup.Item>
                                <ListGroup.Item className="bg-imburse-light px-3">Deductible 500</ListGroup.Item>
                            </ListGroup>
                            <Button variant='primary' className='btn-imburse my-3 d-flex m-auto justify-content-center' size="lg" onClick={ this.continue(policies[policyPosition.basic])} >Continue</Button>
                        </Card.Body>
                    </Card>

                    <Card className='mx-1 w-25 d-flex justify-content-center align-items-center' style={{ 'borderColor': '#adb5bd', 'borderWidth' : 'thin'}}>
                        <Card.Body className="w-100 px-0">
                        <Card.Title className="mb-4 text-center px-3 fw-bold">{policies[policyPosition.premium].title}</Card.Title>
                        <Card.Title className="mb-4 text-center px-3 fw-bold">{policies[policyPosition.premium].amount} {getCurrency()}</Card.Title>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="bg-imburse-light px-3">1 year free of charge</ListGroup.Item>
                                <ListGroup.Item className='px-3'>Sum Insured 100 million</ListGroup.Item>
                                <ListGroup.Item className="bg-imburse-light px-3">Deductible 500</ListGroup.Item>
                                <ListGroup.Item className='px-3'>Drivers from 25th birthday</ListGroup.Item>
                                <ListGroup.Item className="bg-imburse-light px-3">Help Point Plus</ListGroup.Item>
                            </ListGroup>
                            <Button variant='primary' className='btn-imburse my-3 d-flex m-auto justify-content-center' size="lg" onClick={ this.continue(policies[policyPosition.premium])} >Continue</Button>
                        </Card.Body>
                    </Card>

                    <Card className='mx-1 w-25 d-flex justify-content-center align-items-center' style={{ 'borderColor': '#adb5bd', 'borderWidth' : 'thin' }} >
                        <Card.Body className="w-100 px-0">
                        <Card.Title className="mb-4 text-center px-3 fw-bold">{policies[policyPosition.optimum].title}</Card.Title>
                        <Card.Title className="mb-4 text-center px-3 fw-bold">{policies[policyPosition.optimum].amount} {getCurrency()}</Card.Title>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="bg-imburse-light px-3">1 year free of charge</ListGroup.Item>
                                <ListGroup.Item className='px-3'>Sum Insured 100 million</ListGroup.Item>
                                <ListGroup.Item className="bg-imburse-light">Deductible 500</ListGroup.Item>
                                <ListGroup.Item className='px-3'>Help Point Plus</ListGroup.Item>
                            </ListGroup>
                            <Button variant='primary' className='btn-imburse my-3 d-flex m-auto justify-content-center' size="lg" onClick={ this.continue(policies[policyPosition.optimum])} >Continue</Button>
                        </Card.Body>
                    </Card>
                </Form.Group>

                <Button variant='primary' className='my-2 btn-imburse-light' size="lg" onClick={this.goBack}>Back</Button>

                {   /*<div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                    <h6 className='text-imburse'>{JSON.stringify(values, null, 4) }</h6>                    
                    </div>*/}
                {console.log("Policy Cover Page ",JSON.stringify(values, null, 4))}
                
            </Form>
        
        )

    }
}

export default FormPolicyCover