import React, { Component } from 'react';
import logo from '../assets/logo.svg';
import userImg from "../assets/user.svg";
import * as API from '../middleware/api';
import ReactFlagsSelect from 'react-flags-select';
import LogoutButton from './logout-button';
//import { Nav } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

const ProgressBar = (props) => {

    const steps = [ {'index' : 0, 'title' : 'Vehicle details' , 'label' : 'Please provide some vehicle details'},
              {'index' : 1, 'title' : 'Most frequent driver' , 'label' : 'Who is the most frequent driver?'},
              {'index' : 2, 'title' : 'Quote options' , 'label' : 'Please select your level of cover'},
              {'index' : 3, 'title' : 'Contact details' , 'label' : 'Please provide some contact details'},
              {'index' : 4, 'title' : 'Purchase insurance policy' , 'label' : 'Summary and check out'}];

    const initialStep = props.currentStep;
    const currentStep = initialStep;

    console.log("Stepper current step -> ",currentStep);

    return(
            <Stepper activeStep={currentStep} id={props.id} orientation="vertical" className="bg-imburse-light rounded-8" style={{minWidth: '360px'}}>
                {steps.map((steps) => (
                    <Step className="bg-imburse-light text-imburse-light" key={steps.index}>
                        <StepLabel className="text-imburse"> {steps.title} </StepLabel> 
                        <StepContent>
                            <span className="text-imburse">{steps.label}</span>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        );
}

export default ProgressBar;