/*
***********************************************
**************** Summary Page *****************
***********************************************
* Page where a summary of the policy will be  *
* present to the customer                     *
***********************************************
*/
import React, { Component, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import * as API from '../middleware/api';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from '../components/progressbar';
import { AiFillEdit, AiOutlineCheck} from 'react-icons/ai/';

//export class FormSummary extends Component{
function FormSummary(){

    const values = JSON.parse(localStorage.getItem("inputFields"));
    const step = JSON.parse(localStorage.getItem("step"));
    const [country, setCountry] = useState(localStorage.getItem("country") != null ? localStorage.getItem("country") : values.country);
    const [scheme, setScheme] = useState('');
    const [coin, setCoin] = useState(((localStorage.getItem("coin")) != null) ? (localStorage.getItem("currency") + " / " + localStorage.getItem("coin")) : 'EUR / €');
    const currency = ((localStorage.getItem("currency")) != null) ? (localStorage.getItem("currency")) : coin.slice(0,3);
    const [errorMessage, setErrorMessage] = useState('');
    const amount = values.policy.amount;
    const customer = values.firstName + "." + values.lastName + Date.now(); 
    const order = "Insurance_Demo_" + Date.now();
    const instruction = "PAYMENT_" + Date.now();
    const policyId = "XYZ_" + Date.now();

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        console.log('Age Calculates :', age);
        return age;
    }

    const saveInfo = () => {
        localStorage.setItem("coin", coin.slice(coin.indexOf('/') + 1));
        localStorage.setItem("currency", coin.slice(0,3));
        localStorage.setItem("customer",JSON.stringify((values.firstName + "." + values.lastName).toLowerCase()));       
        localStorage.setItem("amount",JSON.stringify(values.policy.amount));
        localStorage.setItem("order",JSON.stringify(order));
        localStorage.setItem("instruction",JSON.stringify(instruction));
        localStorage.setItem("policyId",JSON.stringify(policyId));
    }

    const goBacktoAmend = () => {
        values.amend = true;
        localStorage.setItem("inputFields",JSON.stringify(values));
        window.navigation.navigate("/policy-details");
    }

    const goToCheckout = (async () => {
        document.getElementById('purchasePolicy').disabled = true;
        document.getElementById('backButton').disabled = true;

        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 10);

        if (amount === 0) {
            setErrorMessage('Policy has not been chosen.');
            document.getElementById('purchasePolicy').disabled = false;
            document.getElementById('backButton').disabled = false;
            return;
        }
        if (scheme === '') {
            setErrorMessage('*There is no scheme selected.');
            document.getElementById('purchasePolicy').disabled = false;
            document.getElementById('backButton').disabled = false;
            return;
        }

        saveInfo();
        
        const data = {
            orderRef: order,
            instructionRef: instruction,
            customerRef: customer,
            direction:"DEBIT",
            amount: (amount).toFixed(2),
            currency: coin.slice(0, coin.indexOf('/') - 1),
            country: country,
            schemeId: scheme,
            settledByDate: dateNow.toISOString().slice(0, 10),
            metadata: { 'policyId' : policyId,
                        'policyType' : values.policy.type,
                        'make' : values.make,
                        'model' : values.model
                        }
        }

        API.createOrder(data,(err,res) => {
            if(!err && res){
                const body = {
                    orderRef: data.orderRef,
                    instructionRef: data.instructionRef,
                    expirySeconds : "6000",
                    billingStreet1: values.address,
                    billingStreet2: "", 
                    billingCity : values.city, 
                    billingState : "", 
                    billingCountry : values.country, 
                    billingPostCode : values.postCode, 
                    customerEmail : values.email, 
                    cardholderName : values.firstName + " " + values.lastName,
                    customerSurname : values.lastName,
                    customerFirstName : values.firstName,
                    customerTelephone : values.phone,
                    themeId : (localStorage.getItem("themeId")!= null) ? localStorage.getItem("themeId") : ''
                }
    
                API.createSessionToken(body,(err,res) => {
                    if(!err && res){
                        window.navigation.navigate(`/checkout/${res.data.sessionToken}`);
                    }
                    else {
                        console.log(err);
                    }
                })
            }
            else{
                console.log(err);
            }
        })
    })


    return (
        <>
        <NavBar 
            placement={'end'}
            setCoin={setCoin}
            setScheme={setScheme}
            setCountry={setCountry}
            menu={true}
            home={false}
        />
        <Container fluid>
            <Row className=''>
                <Col className='d-flex justify-content-center align-items-center m-lg-5 col-lg-8' style={{minHeight: (window.innerHeight - '200') , width : '50%'}}>
                    <div className="w-75 mx-5">
                        <div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                            <h2 className='fw-bold text-imburse'>Policy summary</h2>                    
                        </div>

                        <Table responsive="md" borderless className='my-0 mx-1 w-75'>
                            <thead>
                                <tr>
                                    <th className='mx-1' style={{fontSize:"20px"}}>Type</th> <td className='mx-0' style={{fontSize:"22px"}}>{values.policy.title}</td>
                                    <th className='mx-1'style={{fontSize:"20px"}}>Cost</th> <td className='mx-0' style={{fontSize:"22px"}}>{new Intl.NumberFormat( navigator.languages[0], { style: 'currency', currency: currency }).format(values.policy.amount)} </td>
                                </tr>
                            </thead>
                        </Table>

                        <Card.Title className='fw-bold mt-5' >Vehicle</Card.Title>

                        <Table responsive="md" className='my-3 w-75'>
                            <thead>
                                <tr>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Date of registration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='bg-imburse-light'>
                                    <td className='w-25'>{values.make}</td>
                                    <td className='w-25'>{values.model}</td>
                                    <td className='w-25'>{values.dateOfRegistration}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <Card.Title className='mt-5 fw-bold'>Main Driver</Card.Title>
                        <Table responsive="md" className='my-3 w-75'>
                            <thead>
                                <tr>
                                    <th>First name</th>
                                    <th>Last Name</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='bg-imburse-light'>
                                    <td className='w-25'>{values.firstName}</td>
                                    <td className='w-25'>{values.lastName}</td>
                                    <td className='w-25'>{values.address}</td>
                                </tr>
                            </tbody>
                            <thead >
                                <tr >
                                    <th className='pt-4'>Age</th>
                                    <th className='pt-4'>Nationality</th>
                                    <th className='pt-4'>Email Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='bg-imburse-light'>
                                    <td className='w-25'>{getAge(values.dateOfBirth)} y.o. ({values.dateOfBirth}) </td>
                                    <td className='w-25'>{values.nationality}</td>
                                    <td className='w-25'>{values.email}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <Button variant='primary' className='my-5 btn-imburse-light' size="lg" id='backButton' onClick={goBacktoAmend}> <AiFillEdit className='text-imburse' size={"22px"}/> Amend details</Button>
                        <Button variant='primary' className='btn-imburse my-5 mx-1' size="lg" id='purchasePolicy' onClick={goToCheckout}> <AiOutlineCheck className='text-imburse-light' size={"22px"}/> Purchase policy</Button>
                        <div className='error-message'>
                            {errorMessage}
                        </div>

                        {   /*<div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                        <h6 className='text-imburse'>{JSON.stringify(values, null, 4) }</h6>                    
                        </div>*/}
                        {console.log("Policy Summary Page", JSON.stringify(values, null, 4))}

                    </div>
                </Col>
                <Col 
                    className='d-flex justify-content-center align-items-center m-lg-3 my-2 col-lg-2 h-auto'
                    style={{ width : '30%' }}    
                >
                    <ProgressBar
                        currentStep = { step +1 }
                    />
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
        )

    }


export default FormSummary;