/*
***********************************************
**************** VDetails Page ****************
***********************************************
* Page where the vehicle details will be asked*
***********************************************
*/

import React, { Component/*, useState*/} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReactFlagsSelect from 'react-flags-select';
import Select from 'react-select/creatable';


export class FormDriverDetails extends Component{


    //proceed to next step
    continue = e => {
        e.preventDefault();

        localStorage.setItem("inputFields",JSON.stringify(this.props.values));
        console.log("Values saved ... ", this.props.values);

        this.props.nextStep(); 
    }

    goBack = e => {
        e.preventDefault();
        this.props.previousStep();
    }

    residenceOptions = [{label: 'Boston', value: 'Boston'},
                        {label: 'Lisbon', value: 'Lisbon'},
                        {label: 'London',value: 'London'},
                        {label: 'Sydney', value: 'Sydney'},                    
                        {label: 'Wellington', value: 'Wellington'},
                        {label: 'Zurich', value: 'Zurich'}];
    residencePlaceholder = 'Select your residence';

    render(){
        const { values, handleChange, handleInputChange, handleSelectChange } = this.props;

        return (
            <Form className="w-75 mx-5">
                <div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                    <h2 className='fw-bold text-imburse'>Who is the main driver?</h2>                    
                </div>

                <Form.Group className="mb-3" controlId="formDateofRegistartion">
                    <Form.Label>Date of birth</Form.Label>
                    <Form.Control
                        className='rounded-8 inputField'
                        type="date" 
                        placeholder="date" 
                        onChange = {handleInputChange('dateOfBirth')}
                        max={new Date().toISOString().split("T")[0]}
                        value={values.dateOfBirth}

                        />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNacionality">
                    <Form.Label>Nationality </Form.Label>
                    <ReactFlagsSelect id='countries'
                            className='rounded-8 inputField'
                            placeholder="Select country"
                            selected={values.nationality}
                            onSelect={handleChange('nationality')}
                            showSelectedLabel={true}
                            showOptionLabel={true}
                            searchable={false}
                        />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formModel">
                    <Form.Label>City of residence</Form.Label>
                    <Select
                        styles={{
                            control: (provided, state) => ({
                            ...provided,
                            "borderRadius": "8px"
                            })
                        }}
                        className='rounded-8 inputField'
                        placeholder={this.residencePlaceholder}
                        onChange={handleSelectChange('cityOfResidence')}
                        options={this.residenceOptions}
                        closeMenuOnSelect
                        required
                        value = {(values.cityOfResidence !== '')?{ value: values.cityOfResidence, label: values.cityOfResidence } : this.placeholder}
                        />
                </Form.Group>

                <Button variant='primary' className='my-5 btn-imburse-light' size="lg" onClick={this.goBack}>Back</Button>{' '}
                <Button variant='primary' className='btn-imburse my-5' size="lg" onClick={this.continue} >Continue</Button>{' '}

            </Form>
        
        )

    }
}

export default FormDriverDetails