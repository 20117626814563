import React , { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Footer = () => {
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" className="py-5" style={{minHeight: '120px'}}>
                    <Navbar.Brand href="#home">
                        <img
                            src={logo}
                            className="d-inline-block align-top"
                            alt='logo'
                            id='logo'
                            height={'50px'}
                            />
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-center">
                        <Navbar.Text className='text-white mx-4 fs-6 justify-content-evenly'>Overview</Navbar.Text>
                        <Navbar.Text className='text-white mx-4 fs-6 justify-content-evenly'>Features</Navbar.Text>
                        <Navbar.Text className='text-white mx-4 fs-6 justify-content-evenly'>Pricing</Navbar.Text>
                        <Navbar.Text className='text-white mx-4 fs-6 justify-content-evenly'>Help</Navbar.Text>
                        <Navbar.Text className='text-white mx-4 fs-6 justify-content-evenly'>Privacy</Navbar.Text>
                    </Navbar.Collapse>
                    <Nav className="mx-5">
                        <Nav.Link href="">@ 2077 Imburse AG</Nav.Link>
                    </Nav>
            </Navbar>
        </>
    );
}

export default Footer;

