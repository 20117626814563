/*
***********************************************
**************** Checkout Page ****************
***********************************************
* Page where the Imburse form wil be showed to*
* the customer, and the customer will input   *
* payment Data                                *
***********************************************
*/

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Checkout(){
    const customer = JSON.parse(localStorage.getItem('customer'));
    const currency = (localStorage.getItem('currency'));
    const amount = JSON.parse(localStorage.getItem('amount'));
    const order = JSON.parse(localStorage.getItem("order"));
    const instruction = JSON.parse(localStorage.getItem("instruction"));
    const policy = JSON.parse(localStorage.getItem("policy"));
    const policyId = JSON.parse(localStorage.getItem("policyId"));

    const [width, setWindowWidth] = useState(0);

    const responsive = {
        mobile: width < 1023,
        class: width < 1023 ? 'mobile' : 'pc'
    }

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
    },[]);
    
    return (
        <>
        <NavBar 
            placement={'end'}
            menu={false}
            home={false}
        />
        <Container fluid style={{minHeight: (window.innerHeight - '200'), width : '90%'}} >
            <Row className='h-auto mb-2 mt-5'>
                <Col className='d-flex justify-content-end align-items-start m-lg-3 col-lg-7'>
                    <div className="" style={{ width : '80%'}}>
                        <imburse-checkout token={useParams().sessionToken} return-url={`${window.location.origin.toString()}/verify/`} mode="sandbox" />
                    
                    </div>
                </Col>
                <Col className='d-flex justify-content-center align-items-center m-lg-3 my-2 col-lg-2' style={{ width : '30%'}}>
                    <Row >
                        <div className='resume bg-imburse-light rounded-8'>
                            <div className='details bg-imburse-light'>
                                <div className='summary'> 
                                    <h3>Policy details</h3>
                                    <span>Type: {policy.title}</span>
                                    <span>Amount: {new Intl.NumberFormat( navigator.languages[0], { style: 'currency', currency: currency }).format(amount)}</span>
                                    {/*<span>Policy Id: {policyId}</span>*/}
                                </div> 
                                <div className='summary'> 
                                    <h3>Order summary</h3>
                                    <span>Order Reference: {order}</span>
                                    <span>Instruction Reference: {instruction}</span>
                                </div> 
                                <div className='customer border-bottom-0' > 
                                    <h3>Customer details:</h3>
                                    <span>Customer Reference: {customer}</span>
                                </div>
                            </div>
                        </div>
                    </Row> 
                </Col>
            </Row>
            <Row className='d-flex justify-content-start align-items-start mt-0 mb-4' style={{marginLeft: '15%'}} >
                <Button variant='primary' className='mx-5 btn-imburse-light' href='/summary' style={{width: '100px'}} >Back</Button>{' '}
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default Checkout;
