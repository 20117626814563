/*
***********************************************
**************** Contract Page ****************
***********************************************
* Page where contract details will be asked to*
* the customer                                *
***********************************************
*/

import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactFlagsSelect from 'react-flags-select';
import Cleave from 'cleave.js/react';

export class FormContactDetails extends Component{
    //proceed to next step
    continue = e => {
        e.preventDefault();

        localStorage.setItem("inputFields",JSON.stringify(this.props.values));
        console.log("Values saved ... ", this.props.values);

        window.navigation.navigate("./summary");
        
    }

    goBack = e => {
        e.preventDefault();
        this.props.previousStep();
    }

    render(){
        const { values, handleInputChange, handleNumberChange, handleChange } = this.props;

        return (
            <Form className="w-75 mx-5">
                <div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                    <h2 className='fw-bold text-imburse'>Who is the main driver?</h2>                    
                </div>
                <Row className="mb-3 col-9">
                    <Form.Group className="mb-3 w-auto" controlId="formFirstName">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            className='rounded-8 inputField'
                            required
                            type="text"
                            placeholder="First name"
                            defaultValue={values.firstName}
                            onChange={handleInputChange('firstName')}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 w-auto" controlId="formLastName">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            className='rounded-8 inputField'
                            required
                            type="text"
                            placeholder="Last name"
                            defaultValue={values.lastName}
                            onChange={handleInputChange('lastName')}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>  

                <Row className="mb-3 col-9">
                    <Form.Group className="mb-3 w-auto" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            className='rounded-8 inputField' 
                            type="email" 
                            placeholder="Enter email" 
                            defaultValue={values.email}
                            onChange={handleInputChange('email')}
                            />
                    </Form.Group>

                    <Form.Group className="mb-3 col-6" controlId="formPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control 
                            className='rounded-8 inputField'
                            placeholder="Enter you phone number" 
                            defaultValue={values.phone}
                            onChange={handleInputChange('phone')}
                            />

                    </Form.Group>  
                </Row>

                <Row className="mb-3 col-9">
                    <Form.Group className="mb-3 w-auto" controlId="formGridAddress1">
                        <Form.Label>Address</Form.Label>
                        <Form.Control 
                            className='rounded-8 inputField'
                            placeholder="Address" 
                            defaultValue={values.address}
                            onChange={handleInputChange('address')}
                            />
                    </Form.Group>

                    <Form.Group className="mb-3 w-auto" controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        className='rounded-8 inputField' 
                        placeholder="City" 
                        defaultValue={values.city}
                        onChange={handleInputChange('city')}
                        />
                    </Form.Group>  
                </Row>

                <Row className="mb-3 col-9">
                    <Form.Group className="mb-3 w-auto" controlId="formGridZip">
                        <Form.Label>Post Code</Form.Label>
                        <Form.Control
                            className='rounded-8 inputField '
                            placeholder="Post Code" 
                            defaultValue={values.postCode}
                            onChange={handleInputChange('postCode')}
                            />
                    </Form.Group>

                    <Form.Group className="mb-3 w-auto" controlId="formNacionality">
                        <Form.Label>Country </Form.Label>
                        <ReactFlagsSelect id='countries'
                                className='rounded-8 inputField'
                                placeholder="Select country"
                                selected={values.country}
                                onSelect={handleChange('country')}
                                showSelectedLabel={true}
                                showOptionLabel={true}
                                searchable={false}
                            />
                    </Form.Group>
                </Row>                                          

                <Button variant='primary' className='my-5 btn-imburse-light' size="lg" onClick={this.goBack}>Back</Button>{' '}
                <Button variant='primary' className='btn-imburse my-5' size="lg" onClick={this.continue} >Continue</Button>{' '}

            </Form>
        )

    }
}

export default FormContactDetails
