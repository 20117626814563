import React, { useEffect, useState, useRef  } from 'react';
import logo from '../assets/logo.png';
import userImg from "../assets/user.svg";
import * as API from '../middleware/api';
import ReactFlagsSelect from 'react-flags-select';
import LogoutButton from './logout-button';
//import { Nav } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

const NavBar = (props) => {
    const country = (localStorage.getItem("country")) != null ? localStorage.getItem("country") : 'PT';
    const [schemas, setSchemas] = useState([]);
    const [selected, setSelected] = useState(country);
    const [currentCoin, setCurrentCoin] = useState(((localStorage.getItem("coin")) != null) ? (localStorage.getItem("currency") + " / " + localStorage.getItem("coin")) : 'EUR / €');
    const [currentScheme, setCurrentScheme] = useState({name: 'No schemas available'});
    const { user } = useAuth0();
    const { name, email } = user;
    const firstTime = {bool: true , name : (JSON.parse(localStorage.getItem("currentScheme")) != null) ? JSON.parse(localStorage.getItem("currentScheme")).name : '00. Insurance Demo'};

    /*Tootip - make claim*/
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const createBackground = () => {
        const container = document.getElementsByClassName('config')[0];

        const newDiv = document.createElement('div');
        newDiv.id = 'backgroundContainer';
        newDiv.display = 'block';
        newDiv.classList.toggle('active');

        container.appendChild(newDiv);
    }

    const buttonHandler = () => {
        document.getElementById('popup').classList.toggle('active');
        document.getElementById('navbar-button').classList.toggle('active'); 
        document.getElementById('logo').classList.toggle('active'); 

        const backgroundContainer = document.getElementById('backgroundContainer');

        if (backgroundContainer) {
            backgroundContainer.classList.toggle('active'); 
            return;
        }

        createBackground();
    }

    const schemeInArray = (scheme, array) => {
        return array.findIndex((v) =>
            (v.name === scheme.name && v.id === scheme.id)
        ) !== -1;
    }

    useEffect(() => {
        if (props.menu) {
            API.getPayoutSchemes().then((res) => {
                if (res.length) {
                    const { setScheme } = props;

                    if ( (!currentScheme.id || !schemeInArray(currentScheme, res)) ) {
                        setSchemas(res);
                        setCurrentScheme(res[0]);
                        setScheme(res[0].id);
                        if(firstTime.bool){
                            setCurrentScheme(res.find(({name}) => name.includes(firstTime.name)));
                            setScheme(res.find(({name}) => name.includes(firstTime.name)).id);
                            firstTime.bool = false;
                        }
                    }
                }
            });
        }    
    }, [props, currentScheme]);

    const changeCountry = (code) => {
        const { setCountry } = props;
        setCountry(code);
        setSelected(code);
        localStorage.setItem("country", code);
    }

    const changeCoin = (e) => {
        const { setCoin } = props;
        const nextCoin = e.target.innerText;
        
        setCoin(nextCoin);
        setCurrentCoin(nextCoin);

        //save local
        localStorage.setItem("coin", nextCoin.slice(nextCoin.indexOf('/') + 1));
        localStorage.setItem("currency", nextCoin.slice(0,3));
        
        document.getElementsByClassName('customDropDown')[0].style.display = 'none';
    }

    const changeScheme = (e) => {
        const { setScheme } = props;
        setScheme(e.target.getAttribute('scheme-index'));
        setCurrentScheme({
            name: e.target.innerText,
            id: e.target.getAttribute('scheme-index')
        });
        localStorage.setItem("currentScheme",JSON.stringify({name: e.target.innerText, id: e.target.getAttribute('scheme-index')} ));       

    }

    const saveChanges = () => {
        //localStorage.setItem("themeId", document.getElementById('themeId').value);
        //console.log('ThemeId : ',localStorage.getItem("themeId"));
        buttonHandler();
    }

    const coins = ['EUR / €', 'GBP / £', 'CHF / CHF', 'USD / $', 'AUD / AU$', 'NZD / NZ$'];

    document.addEventListener('click', function (event) {
        const btn = document.getElementById('navbar-button');
        const menu = document.getElementById('popup');
        const coinDropDown = document.getElementsByClassName('customDropDown')[0];
        const coinLabel = document.getElementsByClassName('customLabel')[0];
        const schemeDropDown = document.getElementsByClassName('customDropDown')[1];
        const schemeLabel = document.getElementsByClassName('customLabel')[1];

        if (menu) {
            if (!menu.contains(event.target) && !btn.contains(event.target)) {
                menu.classList.remove('active');
                btn.classList.remove('active');
                document.getElementById('logo').classList.remove('active');
                const backgroundContainer = document.getElementById('backgroundContainer');
                if (backgroundContainer) {
                    backgroundContainer.classList.remove('active'); 
                }
            }
        }

        if (coinDropDown) {
            if (!coinLabel.contains(event.target)) {
                coinDropDown.style.display = 'none';
            }
        }

        if (schemeDropDown) {
            if (!schemeLabel.contains(event.target)) {
                schemeDropDown.style.display = 'none';
            }
        }
    });

    return (
        <>
            <Navbar expand="md" className="bg-imburse px-0 config" style={{minHeight: '80px'}}>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            className="d-inline-block align-top"
                            alt='logo'
                            id='logo'
                            height={'50px'}
                            />
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-center">
                        <Navbar.Text className='text-white fs-3 fw-bold'>Insurance Demo</Navbar.Text>
                    </Navbar.Collapse>
                    <Nav className="d-flex justify-content-evenly align-items-center flex-row">
                        <Nav.Link>
                        { (props.home) && <Button className='btn-imburse-light my-0 p-2 mx-2' size="md" ref={target} onClick={() => setShow(!show)}>Make a claim</Button> }{''}
                            <Overlay target={target.current} show={show} placement="left">
                            {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                                not available at the moment ... 
                            </Tooltip>
                            )}
                            </Overlay>
                        </Nav.Link>
                        <Nav.Link href="">
                        {props.menu && <button id='navbar-button' className='rounded-8 my-0 p-2' onClick={buttonHandler} />}
                        </Nav.Link>
                    </Nav>
            </Navbar>
            
            {
                (props.menu) &&
                <div id='popup'>
                        <div id='profile' className='d-flex w-100 h-100 rounded-t-8 justify-content-center'>
                            <div id='profileImage' className='d-flex align-items-center'>
                                <img src={user.picture} alt='profile' className='rounded-circle' />
                            </div>
                            <div id='info'>
                                <div className='header'>{user.name}</div>
                                <div id='email'>{user.email}</div>
                                <Nav className="justify-content-start">
                                    <LogoutButton />
                                </Nav>
                            </div>
                        </div>
                    <div className='w-100 h-100 rounded-b-8'>
                    <div id='selectors' className=''>
                        <div className='header'>Settings</div>
                        <div className='selectLabel d-none'>Country</div>
                        <ReactFlagsSelect id='countries'
                            className=' d-none'
                            style={{ 'border-radius': '8px' }}
                            selected={selected}
                            onSelect={code => changeCountry(code)}
                            countries={["PT", "DE", "GB", "CH", "US", "AU","NZ", "ES"]}
                            customLabels={{
                                "PT": "Portugal",
                                "DE": "Deutchland",
                                "GB": "United Kingdom",
                                "CH": "Switzerland",
                                "US": "United States",
                                "AU": "Australia",
                                "NZ": "New Zealand",
                                "ES": "Spain"
                            }}
                            placeholder="Select country"
                        />

                        <div className='selectLabel  d-none'>Currency</div>
                        <div id='coin' className=' d-none'>
                            <div className='customLabel rounded-8' onClick={() => {
                                const dropdown = document.getElementsByClassName('customDropDown')[0];
                                dropdown.style.display = (dropdown.style.display === 'block') ? 'none' : 'block';
                            }}>
                                {currentCoin}
                            </div>
                            <ul className='customDropDown rounded-8'>
                                {coins.map((value) =>
                                    <li key={value} onClick={changeCoin}>
                                        {value}
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className='selectLabel'>Payment scheme</div>
                        <div id='schemas'>
                            <div className='customLabel rounded-8' onClick={() => {
                                const dropdown = document.getElementsByClassName('customDropDown')[1];
                                dropdown.style.display = (dropdown.style.display === 'block') ? 'none' : 'block';
                            }}>
                                {currentScheme.name}
                            </div>
                            <ul className='customDropDown rounded-8'>
                                {schemas.sort((a,b) => a.name.localeCompare(b.name)).map((value) =>
                                    <li key={value.id} scheme-index={value.id} onClick={changeScheme}>
                                        {value.name}
                                    </li>
                                )}
                            </ul>
                        </div>

                        {/*<div className='selectLabel'>ThemeId</div>
                        <Form.Control id='themeId' type="text" placeholder="Insert your themeId" autocomplete="off" defaultValue=''/>*/}
                    </div>
                    <button id='shuffleButton' onClick={ () => saveChanges()}>Apply changes</button>
                    </div>
                </div>
            }
        </>
    );
}

export default NavBar;