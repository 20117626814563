import React, { useEffect, useState } from 'react';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { AiFillCheckCircle } from 'react-icons/ai/';


function VerifyPayment(){
    const customer = JSON.parse(localStorage.getItem('customer'));
    const coin = (localStorage.getItem('coin'));
    const amount = JSON.parse(localStorage.getItem('amount'));
    const order = JSON.parse(localStorage.getItem("order"));
    const instruction = JSON.parse(localStorage.getItem("instruction"));
    const policy = JSON.parse(localStorage.getItem("policy"));
    const policyId = JSON.parse(localStorage.getItem("policyId"));
    const [width, setWindowWidth] = useState(0);

    const responsive = {
        mobile: width < 1023,
        class: width < 1023 ? 'mobile' : 'pc'
    }

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();

        window.addEventListener("resize", updateDimensions);
    },[]);

    localStorage.removeItem('inputFields');
    localStorage.removeItem('step');
    localStorage.removeItem('currency');
    
    return (
        <>
        <NavBar 
            placement={'end'}
            menu={false}
            home={false}
        />
        <Container fluid style={{minHeight: '80vh'}}>
            <Row className='w-100'>
                <Col className='d-flex justify-content-center align-items-center h-auto'>
                    {/*<div className={'data '+ responsive.class}>
                    </div>*/}
                    <div className='verify my-5 d-flex flex-column align-items-center'>
                        <AiFillCheckCircle className='' size={"80px"} color={"#78D74B"}/>
                        <p className='mt-3 fw-bold'> Payment successful</p>
                        <p>Thank you for your order!</p>
                    </div>
                </Col>
            </Row>
            <Row className='w-100'>
                <Col className='d-flex justify-content-center align-items-start h-auto'>
                    <div className='resume'>
                        <div className='details'>
                            <div className='summary'> 
                                <h3>Policy details</h3>
                                <h4>Policy Id: {policyId}</h4>
                                <span>Type: {policy.title}</span>
                                <span>Amount: {amount}{coin}</span>
                            </div> 
                            <div className='summary'> 
                                <h3>Order summary</h3>
                                <span>orderRef: {order}</span>
                                <span>instructionRef: {instruction}</span>
                            </div> 
                            <div className='customer'> 
                                <h3>Customer details:</h3>
                                <span>customerRef: {customer}</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='w-100'>
                <Col className='d-flex justify-content-center align-items-center h-auto'>
                    <Button variant='primary' className='my-5 btn-imburse-light' style={{ 'minWidth': '140px'}} size="lg" href='/'>Back Home</Button>{' '}
                    <Button variant='primary' className='my-5 mx-5 btn-imburse' style={{ 'minWidth': '140px'}} size="lg" href='/policy-details'>Restart</Button>{' '}
                </Col>
            </Row>
        </Container>
        <Footer />
        </>
    )
}

export default VerifyPayment;