/*
***********************************************
**************** VDetails Page ****************
***********************************************
* Page where the vehicle details will be asked*
***********************************************
*/

import React, { Component /*, useState*/  } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select/creatable';

export class FormVehicleDetails extends Component{
    //proceed to next step
    continue = e => {
        e.preventDefault();

        localStorage.setItem("inputFields",JSON.stringify(this.props.values));
        console.log("Values saved ... ", this.props.values);

        this.props.nextStep();
    }

    goHome = e => {
        e.preventDefault();
        this.props.history.push("./")
    }
    //options for make
    makeOptions = [{label: 'Ferrari',value: 'Ferrari'},
                            {label: 'Honda', value: 'Honda'},
                            {label: 'Mercedes', value: 'Mercedes'}, 
                            {label: 'McLaren', value: 'McLaren'}, 
                            {label: 'Alfa Romeo', value: 'Alfa Romeo'}, 
                            {label: 'Aston Martin', value: 'Aston Martin'}];
    //place holder for make select
    makePlaceholder = 'Select make';
    
    modelOptions = (input) =>{
        switch (input){

        case 'Ferrari':
            return [{label: '488 Spider',value: '488 Spider'},
                    {label: 'California',value: 'California'},
                    {label: 'Enzo',value: 'Enzo'},
                    {label: 'Testa Rossa',value: 'Testa Rossa'}];
        case 'Honda':
            return [{label: 'Accord',value: 'Accord'},
                    {label: 'Civic',value: 'Civic'},
                    {label: 'Civic Type R',value: 'Civic Type R'},
                    {label: 'NSX',value: 'NSX'}];
        case 'Mercedes':
            return [{label: 'C-Class',value: 'C-Class'},
                    {label: 'CLS-Class',value: 'CLS-Class'},
                    {label: 'E-Class',value: 'E-Class'},
                    {label: 'EQA',value: 'EQA'}];
        case 'McLaren':
            return [{label: '720S',value: '720S'},
                    {label: 'P1',value: 'P1'},
                    {label: 'Senna',value: 'Senna'},
                    {label: 'Speedtail',value: 'Speedtail'}];
        case 'Alfa Romeo':
            return [{label: 'Romeo 159',value: 'Romeo 159'},
                    {label: 'Romeo Montreal',value: 'Romeo Montreal'},        
                    {label: 'Romeo Romeo',value: 'Romeo Romeo'},
                    {label: 'Romeo Spider',value: 'Romeo Spider'}];
        case 'Aston Martin':
            return [{label: 'DB11',value: 'DB11'},
                    {label: 'DB Mark III',value: 'DB Mark III'},
                    {label: 'One-77',value: 'One-77'},
                    {label: 'Virage',value: 'Virage'}];
        default:
            return [];
        }
    }

    render(){
        const { values, handleSelectChange, handleInputChange, autoFill, handleChangeManually } = this.props;
      
        const prevFields = JSON.parse(localStorage.getItem("inputFields"));

        const amendDetails = () => {
            console.log('Amendment - data already filled before ...');

            if(prevFields !== null && prevFields !== undefined){
                if(prevFields.amend === true){
                    console.log('values to amend');
                    handleChangeManually('firstName', prevFields.firstName);
                    handleChangeManually('lastName', prevFields.lastName);
                    handleChangeManually('email', prevFields.email);
                    handleChangeManually('phone', prevFields.phone);
                    handleChangeManually('address', prevFields.address);
                    handleChangeManually('city', prevFields.city);
                    handleChangeManually('policy', prevFields.policy);
                    handleChangeManually('dateOfBirth', prevFields.dateOfBirth);
                    handleChangeManually('nationality', prevFields.nationality);
                    handleChangeManually('cityOfResidence', prevFields.cityOfResidence);
                    handleChangeManually('make', prevFields.make);
                    handleChangeManually('model', prevFields.model);
                    handleChangeManually('dateOfRegistration', prevFields.dateOfRegistration);
                    handleChangeManually('amended', true);

                }
                localStorage.setItem("inputFields",JSON.stringify(this.props.values));
            } else {  console.log('...  no ');    }
        }



        if(prevFields !== undefined && prevFields !== null){
            console.log(prevFields);
            if(!values.amended && prevFields.amend ){ 
                amendDetails();
            }
        }

        return (
            
            <Form className="w-75 mx-5" >
                <div className='d-flex justify-content-start align-items-start my-lg-5 w-100 h-auto'>
                    <h2 className='fw-bold text-imburse'>Which vehicle would you like to insure?</h2>                    
                </div>
                <Form.Group className="mb-3" controlId="formMake" >
                    <Form.Label>Make</Form.Label>
                    <Select
                        styles={{
                            control: (provided, state) => ({
                            ...provided,
                            "borderRadius": "8px"
                            })
                        }}
                        className='rounded-8 inputField'
                        placeholder={this.makePlaceholder}
                        onChange={handleSelectChange('make')}
                        options={this.makeOptions}
                        closeMenuOnSelect
                        required
                        value = {(values.make !== '')?{ value: values.make, label: values.make } : this.placeholder}
                        />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formModel">
                    <Form.Label>Model</Form.Label>
                    <Select
                        styles={{
                            control: (provided, state) => ({
                            ...provided,
                            "borderRadius": "8px"
                            })
                        }}
                        className='rounded-8 inputField'
                        placeholder={'Select model'}
                        onChange={handleSelectChange('model')}
                        onInputChange={this.handleInputChange}
                        options={this.modelOptions(values.make)}
                        value = {(values.model !== '')?{ value: values.model, label: values.model } : this.placeholder}
                        />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDateofRegistartion" >
                    <Form.Label>Date of registration</Form.Label>
                    <Form.Control 
                        className='rounded-8 inputField'
                        type="date"
                        dateFormat="yyyy-MM-dd"
                        placeholder="date"
                        onChange = {handleInputChange('dateOfRegistration')}
                        max={new Date().toISOString().split("T")[0]}
                        value={values.dateOfRegistration}
                    />
                </Form.Group>
                
                <Button variant='primary' className='my-5 btn-imburse-light' size="lg" href='/'>Back</Button>{' '}
                <Button variant='primary' className='btn-imburse my-5' size="lg" onClick={this.continue} >Continue</Button>{' '}


                <Button variant='primary' id='auto' className='btn-imburse my-5 d-none' size="lg" onClick={autoFill} >Auto fill</Button>{' '}
 
            </Form>
        )

    }
}

export default FormVehicleDetails