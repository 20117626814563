import React, { Component, useState } from 'react';
import FormVehicleDetails from './formVehicleDetails';
import FormDriverDetails from './formDriverDetails';
import FormPolicyCover from './formPolicyCover';
import FormContactDetails from './formContactDetails';
import FormSummary from '../pages/FormSummary';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from '../components/progressbar';

export class UserForm extends Component{
    state = {
        step : 0,
        firstName : '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        postCode: '',
        policy: '',
        dateOfBirth: '',
        nationality: '',
        cityOfResidence: '',
        make: '',
        model: '',
        dateOfRegistration: '',
        amended: false
    }

    //proceed to next step
    nextStep = () => {
        const {step} = this.state;
        this.setState({ step : step + 1});
        this.render();
    }

    //proceed to next step
    previousStep = () => {
        const {step } = this.state;
        this.setState({ step : step - 1});
        this.render();
    }

    //handle changes on fields
    handleInputChange = input => e => {
        this.setState({[input] : e.target.value})
    }

    //handle changes on fields
    handleChange = input => e => {
        this.setState({[input] : e})
    }

    //handle for Select
    handleSelectChange = input => e => {
        if(!this.isEmpty(e.value)){
            this.setState({[input] : e.value})
        }
    }

    //handle Number Change
    handleNumberChange = input => e => {
        this.setState({[input] : e})
    }

    //handle Select Policy
    handleChangeManually = (input, value) => {
        this.setState({[input] : value})
    }

    isEmpty(str) {
        return !str.trim().length;
    }

    autoFill = () =>{
        console.log('Calling auto fill ...');
        this.setState({ firstName : 'David',
                        lastName: 'Ferreira',
                        email: 'david@teste.pt',
                        phone: '+351 912 276 596',
                        address: 'Rua do Teste',
                        city: 'Lisbon',
                        postCode: '1200-000',
                        country: 'PT',
                        dateOfBirth: '1995-07-23',
                        nationality: 'PT',
                        cityOfResidence: 'Lisbon',
                        make: 'Mercedes',
                        model: 'E-Class',
                        dateOfRegistration: '2022-08-11',
        });
    }

    submitForm = () => {
        localStorage.setItem("policy", "value")
    }

    render(){
        
        const { step } = this.state;
        const {firstName, lastName, email, phone, address, city, postCode, policy, dateOfBirth, nationality, cityOfResidence, make, model, dateOfRegistration, amended, country} = this.state;
        const values = {firstName, lastName, email, phone, address, city, postCode, policy, dateOfBirth, nationality, cityOfResidence, make, model, dateOfRegistration, amended, country};
        

        localStorage.setItem("step", this.state.step);

        switch (step) {
            case 0:
                console.log('Step 1');
                return (
                    <Row className=''>
                        <Col 
                            className='d-flex justify-content-end align-items-center m-lg-4 my-3 col-lg-8 h-auto'
                            style={{ width : '60%' }}
                        >
                            <FormVehicleDetails
                                nextStep = {this.nextStep}
                                handleInputChange = {this.handleInputChange}
                                handleSelectChange = {this.handleSelectChange}
                                handleChangeManually = {this.handleChangeManually}
                                autoFill = {this.autoFill}
                                values = {values}
                            />
                        </Col>
                        <Col
                            className='d-flex justify-content-center align-items-center m-lg-2 col-lg-2 h-auto my-3'
                            style={{ width : '30%' }}>
                            <ProgressBar 
                                currentStep = { this.state.step }
                                id='progressBar' ></ProgressBar>
                        </Col>
                    </Row>
                );
            case 1:
                console.log('Step 2');
                return (
                    <Row className=''>
                        <Col 
                            className='d-flex justify-content-end align-items-center m-lg-4 col-lg-8 h-auto  my-3'
                            style={{ width : '60%' }}
                        >
                            <FormDriverDetails
                            nextStep = {this.nextStep}
                            handleChange = {this.handleChange}
                            handleInputChange = {this.handleInputChange}
                            handleSelectChange = {this.handleSelectChange}
                            previousStep = {this.previousStep}
                            values = {values}
                        />
                        </Col>
                        <Col 
                            className='d-flex justify-content-center align-items-center m-lg-2 col-lg-2 h-auto  my-3'
                            style={{ width : '30%' }}
                        >
                            <ProgressBar 
                                currentStep = { this.state.step }
                                id='progressBar' ></ProgressBar>
                        </Col>
                    </Row>
                    
                );
            case 2:
                console.log('Step 3');
                return (
                    <Row className=''>
                        <Col 
                            className='d-flex justify-content-end align-items-center m-lg-4 col-lg-8 h-auto  my-3' 
                            style={{ width : '60%' }}
                        >
                            <FormPolicyCover
                            nextStep = {this.nextStep}
                            handleChange = {this.handleChange}
                            previousStep = {this.previousStep}
                            handlePolicyChange = {this.handleChangeManually}
                            values = {values}
                            />
                        </Col>
                        <Col 
                            className='d-flex justify-content-center align-items-center m-lg-2 col-lg-2 h-auto  my-3'
                            style={{ width : '30%' }}
                        >
                            <ProgressBar 
                                currentStep = { this.state.step }
                                id='progressBar' ></ProgressBar>
                        </Col>
                    </Row>
                );
           case 3:
            console.log('Step 4');
                return (
                    <Row className=''>
                        <Col 
                            className='d-flex justify-content-end align-items-center m-lg-4 col-lg-8 h-auto  my-3'
                            style={{ width : '60%' }}
                        > 
                            <FormContactDetails
                            nextStep = {this.nextStep}
                            handleChange = {this.handleChange}
                            handleInputChange = {this.handleInputChange}
                            previousStep = {this.previousStep}
                            handleNumberChange = {this.handleChangeManually}
                            values = {values}
                            />
                        </Col>
                        <Col 
                            className='d-flex justify-content-center align-items-center m-lg-2 col-lg-2 h-auto  my-3'
                            style={{ width : '30%' }}
                        >
                            <ProgressBar 
                                currentStep = { this.state.step }
                                id='progressBar' ></ProgressBar>
                        </Col>
                    </Row>
                );
            default:
                console.log('Default Error');
        };
    }
}

export default UserForm