/*
***********************************************
**************** VDetails Page ****************
***********************************************
* Page where the vehicle details will be asked*
***********************************************
*/

import React, { useEffect, useState } from 'react';
import NavBar from '../components/navbar';
import ProgressBar from '../components/progressbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../components/footer';
import options1 from '../assets/options1.jpg';
import { useParams } from "react-router-dom";
import Loading from '../components/loading';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useHistory } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import pic from '../assets/car_insurance.jpg';
import Form from 'react-bootstrap/Form';
import { UserForm } from '../components/userForm'



function FormPage(){
    let history = useHistory();
    const [total, setTotal] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [coin, setCoin] = useState(((localStorage.getItem("coin")) != null) ? (localStorage.getItem("currency") + " / " + localStorage.getItem("coin")) : 'EUR / €');
    const [shipping, setShipping] = useState(0.73 * 4);
    const [loading, setLoading] = useState(false);
    const [scheme, setScheme] = useState('');
    const [country, setCountry] = useState('PT');
    const [errorMessage, setErrorMessage] = useState('');
    const [refreshState, setRefreshState] = useState(true);

    const step = JSON.parse(localStorage.getItem("step"));

    return (
        <>
        <NavBar 
            placement={'end'}
            setCoin={setCoin}
            setScheme={setScheme}
            setCountry={setCountry}
            menu={true}
            home={false}
        />
        <Container fluid style={{minHeight: (window.innerHeight - '200')}} className=''>
            <UserForm></UserForm>
            {/*<Row className=''>
                <Col className='d-flex justify-content-center align-items-center m-lg-5 col-lg-8 h-auto'>
                    <UserForm></UserForm>
                </Col>
                <Col className='d-flex justify-content-center align-items-center m-lg-3 col-lg-2 h-auto w-auto'>
                    <ProgressBar 
                        currentStep = { step - 1 }
                        id='progressBar' ></ProgressBar>
                </Col>
            </Row>*/}
        </Container>
        <Footer />
        </>
    )
}

export default FormPage;
